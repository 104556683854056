/* * {
  -webkit-overflow-scrolling: touch;
} */
body, html {
  font-family: 'Josefin Sans', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  color: white;

}
span{
  color: lightblue;
  text-transform: uppercase;
  font-size: 4vh;
}
.AboutBlock-1, .AboutBlock-2, .AboutBlock-3{
  background-color: #ac7791;
  padding: 5%;
  box-shadow: 4px 5px 6px 0px #494949a6;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
/* .AboutBlock-2-inner{
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
} */
.move-top {
    border: none;
    background: transparent;
    font-family: 'Josefin Sans', sans-serif;
    font-size: calc(10px + 2vmin);
    color: white;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  opacity: 0;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: none;
  }
}
.App-logo {
    -webkit-animation: App-logo-spin 10s linear;
  } */


.App-header {
  background-color: #6d3d55;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  margin: 2%;
  height: 20vmin;

}
/* all the svg icon links are different and the 
GrDocumentText icon has an default black color
for some reason*/
.Svg-path path {
  fill: #61dafb08;
  stroke: #61dafb;
}


.Links{
  display: flex;
  justify-content: center;
}
.CoverScreen
{
    height: auto;
    min-height: 100vh;
    padding: 5%;
    display: flex;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
}
.Round {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: 5%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #a97f9a;
 
}
.Pic-1 {
  background-image: url('../pictures/IMG_4828.jpg');
}


.Custom-Parallax {
  background-image: url("../pictures/justbebold.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.container{
  width: 10vh;
  height: 10vh;
  display: inline-block;
}

/* 
@-webkit-keyframes App-logo-spin {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
 */

 @media only screen and (max-width: 768px) {
  .Custom-Parallax {
    background-image: url("../pictures/pion.png");
    background-attachment: scroll;
  }
  .AboutBlock-1{
    height: 77vh;
  }



}

@media only screen and (min-width: 769px) {
  .AboutBlock-2-inner{
    max-width: 60%;
  }
}
